<template>
  <b-card>
    <div v-for="(question, indexQ) in questions" :key="indexQ">
      <b-card-text class="mt-1 text-black form-check-inline m-0 p-0">
        <h5 class="text-black">{{++indexQ}}. </h5> &nbsp;<h5 class="text-black" v-html="question ? question.question : ''"></h5>
      </b-card-text>
      <br>
      <b-card-text class="mt-1 text-black form-check-inline m-0 p-0">
        <span class="text-black">Ответ:</span> &nbsp;<span class="text-black" v-html="question ? question.answer : ''"></span>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormRadio, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BCardText,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      questions: [],
    }
  },
  methods: {
    view() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/report/feedback/${this.id}/view/${this.userID}`, {})
        .then(response => {
          const {
            data,
          } = response.data
          this.questions = data
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
        })
    },
  },
  created() {
    this.view()
  },
  setup() {
    const id = router.currentRoute.params.id
    const userID = router.currentRoute.params.userID
    return {
      id,
      userID,
    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label{
  color: #222222 !important;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
